// @ngInject
function externalAccountClient(webapiResource) {
  var client = {};
  client.getExternalAccountInfo = webapiResource({
    method: 'GET',
    path: '/v2/expensemgmt/external/account',
    cache: false
  });
  return {
    getExternalAccountInfo: client.getExternalAccountInfo
  };
}
export default externalAccountClient;
