import acpCore from 'core';
import ng from 'angular';
import externalAccountClient from './external-account-client';
import externalAccountModel from './external-account-model';
var component = ng
  .module('acp.component.external-account-service', [acpCore.name])
  .service('externalAccountModel', externalAccountModel)
  .service('externalAccountClient', externalAccountClient);

export default component;
