// @ngInject
function externalAccountModel(externalAccountClient) {
  var model = {};
  model.getExternalAccountInfo = function() {
    return externalAccountClient.getExternalAccountInfo();
  };

  return model;
}
export default externalAccountModel;
